//
//
//
//
//

import { initWx } from '../components/minxin'
import createBillMsg from './components/createBillMsg.vue'
export default {
    components: {
        createBillMsg,
    },
    mixins: [initWx],
    mounted() {
        this.$refs.createBillMsg.get_info(this.$route.query)
    },
    methods: {
        changeTab(index) {
            this.$router.go(-1)
        },
    },
}
